import { createSelector, createFeatureSelector } from "@ngrx/store";
import { AuthState } from "./reducer";
import { AppState } from "..";

export const selectAuthState = createFeatureSelector<AppState, AuthState>('authState')

export const AccessToken = createSelector(
    selectAuthState,
    (state: AuthState) => state.accessToken
)
export const RefreshToken = createSelector(
    selectAuthState,
    (state: AuthState) => state.refreshToken
)

export const ValidToken = createSelector(
    selectAuthState,
    (state: AuthState) => state.refreshToken !== "" && state.refreshToken !== undefined 
    && state.accessToken !== "" && state.accessToken !== undefined
)
export const loginUserId = createSelector(
    selectAuthState,
    (state: AuthState) => state.userId
)

export const getcontrolCompleted = createSelector(
    selectAuthState,
    (state: AuthState) => state.controlCompleted
)

export const getUserDetails = createSelector(
    selectAuthState,
    (state: AuthState) => state.userDetails
)

export const getUserObject = createSelector(
    selectAuthState,
    (state: AuthState) => state.userDetails?.Data[0]
)
export const PatientAlertData = createSelector(
    selectAuthState,
    (state: AuthState) => state.patientAlertData
)
export const controlsList = createSelector(
    selectAuthState,
    (state: AuthState) => state.controls
)

export const userRole = createSelector(
    selectAuthState,
    (state: AuthState) => state.userRoleData?.Data
)